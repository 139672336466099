import moment from "moment-timezone";
import { useState } from "react";
import { useContext } from 'react';
import { FetchContext } from '../context/FetchContext';
import { FaFileDownload } from "react-icons/fa";

const CustomTable = ({ data }: any) => {
	if(data){
		data.length = 6;
	}
	// const fetchContext = useContext(FetchContext);
	// const downloadLog = async() => {
	// 	try {
	// 		const { data } = await fetchContext.authAxios.get('download').then((res: { data: Blob | MediaSource; }) => console.log(URL.createObjectURL(res.data)))
	// 		.then((blob: Blob | MediaSource) => {
	// 		  const link = document.createElement("a");
	// 		  link.href = URL.createObjectURL(blob);
	// 		  link.setAttribute("download", "file.pdf");
	// 		  document.body.appendChild(link);
	// 		  link.click();
	// 		})
	// 	  ;
	// 		return data?.data;
	// 	} catch (error) {
	// 		console.log(`error?.response	`, error);
	// 	}
	// }
	const [showMore, setShowMore] = useState(false);
	return (
		<div className="flex flex-col p-10">
			<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<div className="shadow-md overflow-hidden border-b border-gray-200 sm:rounded-lg">
						<table className="min-w-full divide-y divide-gray-200">
							<thead className="bg-gray-50">
								<tr>
									<th
										scope="col"
										className="px-6 py-3 text-center text-xs font-mediums text-gray-900 tracking-wider"
									>
										Api Source
									</th>
									<th
										scope="col"
										className="px-6 py-3 text-center text-xs font-mediums text-gray-900 tracking-wider"
									>
										Datahub Shops
									</th>
									<th
										scope="col"
										className="px-6 py-3 text-center text-xs font-mediums text-gray-900 tracking-wider"
									>
										Shops loaded In Database
									</th>
									<th
										scope="col"
										className="px-6 py-3 text-center text-xs font-mediums text-gray-900 tracking-wider"
									>
										Newly Added Shops In Database
									</th>
									<th
										scope="col"
										className="px-6 py-3 text-center text-xs font-mediums text-gray-900 tracking-wider"
									>
										Datahub Products
									</th>
									<th
										scope="col"
										className="px-6 py-3 text-center text-xs font-mediums text-gray-900 tracking-wider"
									>
										Products loaded In Database
									</th>
									<th
										scope="col"
										className="px-6 py-3 text-left text-xs font-mediums text-gray-900 tracking-wider"
									>
										Start Time (EET)
									</th>
									<th
										scope="col"
										className="px-6 py-3 text-left text-xs font-mediums text-gray-900 tracking-wider"
									>
										End Time (EET)
									</th>
									<th scope="col" className="px-6 py-3 text-center text-xs font-mediums text-gray-900 tracking-wider">
										Download log
									</th>
								</tr>
							</thead>
							<tbody className="bg-white divide-y divide-gray-200">
								{data?.length > 0 && showMore &&
									data?.map((log: any, key:any) => (
										<tr key={key + 1}>
											{/* <td className="px-6 py-4 whitespace-nowrap">
												<div className="flex items-center">
													<div className="flex-shrink-0 h-10 w-10">
														<img
															className="h-10 w-10 rounded-full"
															src={
																'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61'
															}
															alt=""
														/>
													</div>
													<div className="ml-4">
														<div className="text-sm font-medium text-gray-900">
															{log.api_type}
														</div>
													</div>
												</div>
											</td> */}
											<td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-900">
												<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
													{log.api_type}
												</span>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm font-medium text-gray-900">
													{log.total_shops}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm font-medium text-gray-900">
													{log?.added_shops ? log.total_shops + log.added_shops : log.total_shops}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm font-medium text-gray-900">
													{log.added_shops}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm font-medium text-gray-900">
													{log.total_products}
												</div>		
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm font-medium text-gray-900">
													{log.added_products}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm text-gray-900 text-center">
													{moment(log.start_time).tz("Europe/Helsinki").format('DD-MM-YYYY HH:mm:ss')}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm text-gray-900 text-center">
													{moment(log.end_time).tz("Europe/Helsinki").format('DD-MM-YYYY HH:mm:ss')}
												</div>
											</td>
											<td>
												{/* <div className="text-sm text-gray-900" onClick={()=> downloadLog()}>logs</div> */}
												<a href={`${process.env.REACT_APP_API_URL}download/${log?.api_type == 'johku' ? 'Johku' : 'Bokun'}/${log?.file_name}`} target="_blank" className="text-sm text-gray-900" style={{display:'flex',justifyContent:'center'}}>
													<FaFileDownload />
												</a>
											</td>
										</tr>
								))}
								{data?.length > 0 && !showMore &&
									(
										<tr >
											<td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-900">
												<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
													{data[0].api_type}
												</span>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm font-medium text-gray-900">
													{data[0].total_shops}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm font-medium text-gray-900">
													{data[0]?.added_shops ? data[0].total_shops + data[0].added_shops : data[0].total_shops}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm font-medium text-gray-900">
													{data[0].added_shops}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm font-medium text-gray-900">
													{data[0].total_products}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm font-medium text-gray-900">
													{data[0].added_products}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm text-gray-900 text-center">
													{moment(data[0].start_time).tz("Europe/Helsinki").format('DD-MM-YYYY hh:mm:ss')}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-center">
												<div className="text-sm text-gray-900 text-center">
													{moment(data[0].end_time).tz("Europe/Helsinki").format('DD-MM-YYYY hh:mm:ss')}
												</div>
											</td>
											<td>
												{/* <div className="text-sm text-gray-900" onClick={()=> downloadLog()}>logs</div> */}
												<a href={`${process.env.REACT_APP_API_URL}download/${data[0]?.api_type == 'johku' ? 'Johku' : 'Bokun'}/${data[0]?.file_name}`} target="_blank" className="text-sm text-gray-900" style={{display:'flex',justifyContent:'center'}}>
												<FaFileDownload />
												</a>
											</td>
										</tr>
								)}
							</tbody>
							<tfoot>
								<tr className="w-full">
									<th style={{float:'right'}} className="text-right tracking-wider">
										<span className="" style={{cursor:'pointer'}} onClick={()=> setShowMore(!showMore)} >{!showMore ? `Show more` : 'Show less'}</span>
									</th>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CustomTable;
